import Analytics from '@/plugins/analytics'
import { emitSdkEvent } from '@/util/sdk'
import {
  SDK_EVENT_TYPES,
  USER_DEVICE_INTERACTION_EVENTS
} from '@/util/constants'
import { startTask } from '@/util/client-side-automation/automation-workflow'

export async function userWasAuthenticated({ store, demo }) {
  Analytics.get().track({
    event: 'Native SDK User Authenticated',
    internal: true
  })

  emitSdkEvent(SDK_EVENT_TYPES.INTERACTION, {
    name: USER_DEVICE_INTERACTION_EVENTS.CLOSED,
    value: {}
  })

  emitSdkEvent(SDK_EVENT_TYPES.AUTOMATION_HANDOFF, {
    type: 'create-background-task'
  })

  if (
    !store.getters['userDeviceAutomation/parsedUserAutomationDeviceConfig']
      .keepAliveAfterAuth
  ) {
    await startTask({ store, demo })

    await store.dispatch('userDeviceAutomation/updateActiveSession', {
      updates: { authenticationComplete: true }
    })
  }
}
