import { makeRequest } from '@/util/requests'
import {
  ATOMIC_BRANDING,
  PRODUCTS,
  SCOPES,
  USER_MESSAGE_TYPES
} from '@/util/constants'
import {
  checkIfProductExistsInTaskWorkflowProducts,
  productQueryString
} from '@/util/task-workflow'
import { customCSSIsEnabledAndUsed, setThemeFromDatabase } from '@/util/theme'
import { demoOverride } from '@/util/demo'
import { ApiError } from '@/util/custom-errors'
import { mergeCustomerAndSdkFeatures } from '@/util/general'
import { PayLinkService, TaskWorkflowOrchestrator } from '@/services'
import Ably from '@/plugins/ably'
import { cardIsValidPaymentMethod } from '@/util/pay-link'

const state = {
  userData: '',
  account: undefined,
  showAccountInformation: false,
  customerOverrides: undefined,
  isLoading: false,
  employmentData: undefined
}

const getters = {
  userIsCypressTest: (state) => {
    const firstName = state?.user?.userData?.names[0]?.firstName
    const lastName = state?.user?.userData?.names[0]?.lastName

    return firstName === 'Cypress' && lastName === 'Test'
  },
  userHasMultipleAccounts: (state) => {
    return state.userData?.accounts && state.userData?.accounts.length > 1
  },
  cardPaymentMethods: (state) =>
    (state.userData?.cards ?? []).filter(cardIsValidPaymentMethod),
  customerName: (state) => {
    return (
      state.customerOverrides?.name ||
      state?.userData?.customer?.name ||
      ATOMIC_BRANDING.NAME
    )
  },
  customerId: (state) => {
    return state?.userData?.customer?._id
  },
  experimentsAllowed: (state) => {
    return (
      state?.userData?.customer?.features?.transactExperiments?.enabled ?? false
    )
  },
  surveysAllowed: (state) => {
    return (
      state?.userData?.customer?.features?.transactSurveys?.enabled ?? false
    )
  },
  customerTags: (state) => {
    return state?.userData?.customer?.tags
      ? [...state.userData.customer.tags]
      : undefined
  },
  parentCustomerName: (state) => {
    return state?.userData?.customer?.parentCustomer?.name
  },
  parentCustomerId: (state) => {
    return state?.userData?.customer?.parentCustomer?._id
  },
  customerLogo: (state, _, rootState, rootGetters) => {
    const darkMode = rootState.theme?.custom?.dark
    const defaultLogo = rootGetters['theme/getIcon']('bankBuilding')
    const lightLogo = state.userData.customer?.branding?.logo?.url
    const darkLogo = state.userData.customer?.branding?.darkLogo?.url

    return (darkMode ? darkLogo : lightLogo) || defaultLogo
  },
  accountNumberLastFour: (state) => {
    if (!state.account) return
    const { accountNumber } = state.account
    return accountNumber.slice(accountNumber.length - 4, accountNumber.length)
  },
  defaultDistributionType: (state) => {
    return state?.userData?.customer?.features?.fractionalDeposits?.distribution
      ?.default
  },
  userIdentifier: (state) => {
    return state?.userData?.identifier
  },
  suggestedCompanyExclusions: (state) => {
    return state?.userData?.customer?.suggestedCompanyRules?.exclusions ?? []
  },
  hideShowAccountButton: (state, _, rootState) => {
    return state.showAccountInformation && !rootState.main.inSdk
  },
  incomeSourcesOfTypeChecking: (state) => {
    return (
      state.userData?.incomeSources?.filter(
        (source) => source.data.type === 'CHECKING'
      ) ?? []
    )
  },
  showDetectedIncomeSourcesFlow: (state) => {
    return state.userData.customer.features?.detectedIncomeSourcesFlow?.enabled
  }
}

const actions = {
  async fetchUser({ commit, dispatch, rootGetters, rootState }, config) {
    try {
      const response = await makeRequest({
        method: 'get',
        endpoint: `/user?scope=${rootState.main.scope}&${productQueryString()}`,
        timeout: 18000
      })
      const theme =
        response.data.data.user?.customer?.transactConfiguration?.theme

      if (response.data.data.linkedAccounts) {
        await dispatch(
          'taskHistory/updateLinkedAccountList',
          response.data.data.linkedAccounts,
          {
            root: true
          }
        )
      }

      await commit('setUser', response.data.data.user)
      if (rootGetters['demo/demoModeEnabled']) {
        demoOverride()
      }
      if (checkIfProductExistsInTaskWorkflowProducts(PRODUCTS.DEPOSIT)) {
        const accounts = response.data.data.user.accounts
        await dispatch('filterAccounts', accounts || [])
      }
      await dispatch(
        'main/updateFeatures',
        mergeCustomerAndSdkFeatures({
          customerFeatures: response.data.data.user.customer.features,
          sdkFeatures: config.features
        }),
        { root: true }
      )

      await dispatch(
        'distribution/updateFixedDistributionLimits',
        response.data.data.user.customer.features?.fractionalDeposits
          ?.distribution?.fixedLimits,
        { root: true }
      )
      await dispatch(
        'distribution/updatePercentDistributionLimits',
        response.data.data.user.customer.features?.fractionalDeposits
          ?.distribution?.percentLimits,
        { root: true }
      )
      await dispatch(
        'main/updateEnvironment',
        response?.headers['x-environment'],
        { root: true }
      )
      await dispatch('experiment/fetchLdFeatureFlags', {}, { root: true })
      if (theme) {
        const customCSSEnabledAndUsed = customCSSIsEnabledAndUsed({
          userData: response.data.data.user
        })

        await setThemeFromDatabase({
          theme,
          customCSSEnabledAndUsed
        })
      }
      if (response.data.data.user?.customer?.transactConfiguration?.i18n) {
        await dispatch(
          'i18n/updateCustomerPhrases',
          response.data.data.user.customer.transactConfiguration.i18n,
          { root: true }
        )
      }

      if (response.data.data.tasks) {
        await dispatch('taskHistory/updateTaskList', response.data.data.tasks, {
          root: true
        })
      }

      if (rootState.main.scope === SCOPES.PAY_LINK) {
        if (response.data.data.userCompanySelections) {
          dispatch(
            'payLink/updateSelections',
            response.data.data.userCompanySelections,
            {
              root: true
            }
          )
          window.atomicStorage.setItem(
            'userCompanySelectionCount',
            response.data.data.userCompanySelections?.length || 0
          )
        }

        TaskWorkflowOrchestrator.initialize({
          Ably,
          channelName: response.data.data.taskWorkflowOrchestratorChannelName
        })

        TaskWorkflowOrchestrator.subscribe({
          messageType: USER_MESSAGE_TYPES.ALL_EVENTS,
          subscriptionHandler: (message) => {
            dispatch(
              'payLink/updateSelections',
              message.data.state?.userCompanySelections,
              {
                root: true
              }
            )
          }
        })

        PayLinkService.initialize({
          pciEndpoint: rootState.environment.pciEndpoint
        })
      }
    } catch (error) {
      console.error(new ApiError(error.message, error.response))
    }
  },

  filterAccounts({ commit }, accounts) {
    let filteredAccounts = []
    accounts.forEach((account) => {
      if (checkIfProductExistsInTaskWorkflowProducts(PRODUCTS.DEPOSIT)) {
        if (account.type === 'checking' || account.type === 'savings') {
          filteredAccounts.push(account)
        }
      }
    })
    commit('setUserAccounts', filteredAccounts)
    if (filteredAccounts.length === 1) {
      commit('setAccount', filteredAccounts[0])
    }
  },
  updateCard({ commit }, updatedCard) {
    const updatedCards = state.userData.cards.map((card) => {
      if (updatedCard._id === card._id) {
        return {
          ...card,
          ...updatedCard
        }
      }
    })

    commit('setCards', updatedCards)
  },
  updateAccount({ commit }, payload) {
    commit('setAccount', payload)
  },
  updateShowAccountInformation({ commit }, value) {
    commit('setShowAccountInformation', value)
  },
  updateCustomerName({ commit }, customerName) {
    commit('setCustomerName', customerName)
  },
  updateCustomerLogo({ commit }, customerLogo) {
    commit('setCustomerLogo', customerLogo)
  },
  updateUsersName({ commit }, name) {
    commit('setUsersName', name)
  },
  updateCustomerOverrides({ commit }, customer) {
    commit('setCustomerOverrides', customer)
  },
  updateIsLoading({ commit }, isLoading) {
    commit('setIsLoading', isLoading)
  },
  updateEmploymentData({ commit }, employmentData) {
    commit('setEmploymentData', employmentData)
  }
}

const mutations = {
  setUser: (state, user) => {
    state.userData = user

    // Used by Cypress tests for debugging
    window.userId = user._id
    window.userIdentifier = user.identifier
  },
  setUserAccounts: (state, accounts) => {
    state.userData.accounts = accounts
  },
  setCards: (state, cards) => {
    state.userData.cards = cards
  },
  setAccount: (state, account) => {
    state.account = account
  },
  setShowAccountInformation: (state, value) => {
    state.showAccountInformation = value
  },
  setCustomerName: (state, customerName) => {
    state.userData.customer.name = customerName
  },
  setCustomerLogo: (state, customerLogo) => {
    state.userData.customer.branding.logo.url = customerLogo
  },
  setUsersName: (state, name) => {
    state.userData.names = {}
    state.userData.names.fullName = name
  },
  setCustomerOverrides: (state, customer) => {
    state.customerOverrides = customer
  },
  setIsLoading: (state, value) => {
    state.isLoading = value
  },
  setEmploymentData: (state, employmentData) => {
    state.employmentData = employmentData
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
