import {
  CONNECTOR_USER_IDENTIFIERS,
  TASK_WORKFLOW_VIEW,
  TASK_WORKFLOW_RESULT,
  HANDOFF_EVENTS,
  SDK_EVENT_TYPES,
  EXIT_REASONS,
  PAGES,
  TASK_FAIL_REASON
} from '@/util/constants'
import { store } from '@/store'
import {
  pauseProgressAnimation,
  fadeOutTaskWorkflowView,
  finishProgressToFinishAnimation,
  startTaskViewChangeFromFinish,
  finishTaskViewChangeFromFinish
} from '@/util/animation'
import { get } from 'lodash-es'
import Analytics from '@/plugins/analytics'
import { appClose } from '@/util/app-event'
import { shouldUseHandoff } from '@/util/app-event'
import { resetProgressToAuthentication } from '@/util/progress/progress-actions'

/**
 * Creates a global timer for the task.
 * If timer expires, it asks the user if they want to keep waiting for the task to finish
 */
export function createTaskTimer() {
  const isHighLatencyTest = Object.values(CONNECTOR_USER_IDENTIFIERS).some(
    (userIdentifier) =>
      store.state.formFlow?.formData?.auth?.[userIdentifier]?.value?.startsWith(
        'test-high-latency'
      )
  )
  const highLatencyTimeoutForTest = 3000
  const highLatencyTimeoutWithBuffer =
    get(
      store.state.company,
      'connector.taskAuthenticationDurationStatistics.percentiles[95].milliseconds',
      60000
    ) * 1.2

  return setTimeout(
    () => {
      const analyticsInstance = Analytics.get()
      analyticsInstance.track({
        event: `Viewed ${PAGES.HIGH_LATENCY}`
      })
      if (
        shouldUseHandoff({ store, handoffEvent: HANDOFF_EVENTS.HIGH_LATENCY })
      ) {
        appClose({
          store,
          eventType: SDK_EVENT_TYPES.CLOSE,
          exitReason: EXIT_REASONS.MANUAL_FALLBACK,
          handoffEvent: HANDOFF_EVENTS.HIGH_LATENCY
        })
      } else {
        _triggerHighLatency()
      }
    },
    isHighLatencyTest ? highLatencyTimeoutForTest : highLatencyTimeoutWithBuffer
  )
}

/**
 * If the user says they want to keep waiting, a 60s timer starts.
 * When it expires, it prompts them to see if they want to keep waiting again.
 */
export function createFulfillmentTimer() {
  return setTimeout(() => {
    _triggerHighLatency()
  }, 60000)
}

/**
 * Triggers a retry for authentication because credentials failed
 */
export async function resetTaskState({ initialView, analyticEvent }) {
  const analyticsInstance = Analytics.get()

  await startTaskViewChangeFromFinish()
  await store.dispatch('taskWorkflow/resetTaskWorkflowState', {
    initialView
  })
  await store.dispatch('progress/updateProgressHook', {
    track: undefined,
    fill: undefined,
    state: undefined
  })

  await store.dispatch('main/resetLinkedAccount')
  await store.dispatch('task/resetTaskIdToConvert')
  await store.dispatch('smartAuth/resetAuthState')

  await finishTaskViewChangeFromFinish()
  resetProgressToAuthentication()

  analyticsInstance.track(analyticEvent)
}

export function allowUserToSeeRecoveryOptions(store) {
  const recoveryOptionsFailReasons = [
    TASK_FAIL_REASON.BAD_CREDENTIALS,
    TASK_FAIL_REASON.ACCOUNT_LOCKOUT,
    TASK_FAIL_REASON.EXPIRED
  ]

  return recoveryOptionsFailReasons.includes(store.state.task.failReason)
}

export function allowUserToTryTaskAgain(store) {
  const tryAgainFailReasons = [
    TASK_FAIL_REASON.BAD_CREDENTIALS,
    TASK_FAIL_REASON.WORK_STATUS_TERMINATED,
    TASK_FAIL_REASON.NO_DATA_FOUND,
    TASK_FAIL_REASON.DISTRIBUTION_NOT_SUPPORTED,
    TASK_FAIL_REASON.SESSION_TIMEOUT,
    TASK_FAIL_REASON.SYSTEM_UNAVAILABLE,
    TASK_FAIL_REASON.CONNECTION_ERROR,
    TASK_FAIL_REASON.DEVICE_DISCONNECTED,
    TASK_FAIL_REASON.UNKNOWN_FAILURE
  ]

  return tryAgainFailReasons.includes(store.state.task.failReason)
}

export function allowUserToReturnToCustomerApp(store) {
  const returnToCustomerAppFailReasons = [
    TASK_FAIL_REASON.ROUTING_NUMBER_NOT_SUPPORTED,
    TASK_FAIL_REASON.PRODUCT_NOT_SUPPORTED,
    TASK_FAIL_REASON.ACCOUNT_UNUSABLE,
    TASK_FAIL_REASON.ENROLLED_IN_PAYCARD,
    TASK_FAIL_REASON.TRANSACTION_PENDING,
    TASK_FAIL_REASON.ACCOUNT_SETUP_INCOMPLETE,
    TASK_FAIL_REASON.USER_DATA_REJECTED
  ]

  return returnToCustomerAppFailReasons.includes(store.state.task.failReason) &&
    store.state.main.inSdk
    ? true
    : false
}

async function _triggerHighLatency() {
  pauseProgressAnimation()
  await fadeOutTaskWorkflowView()
  await store.dispatch('taskWorkflow/updateTaskWorkflowState', {
    view: TASK_WORKFLOW_VIEW.FINISHED,
    result: TASK_WORKFLOW_RESULT.FULFILLMENT_AVAILABLE
  })
  finishProgressToFinishAnimation()

  await store.dispatch('task/clearAllTaskTimers')
}
