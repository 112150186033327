import { last } from 'lodash-es'
import {
  PAY_LINK_USER_STATUS,
  ROUTES,
  DEEPLINK_STEPS,
  BILL_PAYMENT_METHOD_TYPES,
  CARD_SOURCE
} from '@/util/constants'

export function determineInitialRoute({ deeplink, userStatus }) {
  const deeplinkToSearch = deeplink?.step === DEEPLINK_STEPS.SEARCH_COMPANY
  const deeplinkToAddCard = deeplink?.step === DEEPLINK_STEPS.ADD_CARD

  if (deeplinkToAddCard) {
    return ROUTES.ADD_CARD
  }

  const statusRouteMapping = {
    [PAY_LINK_USER_STATUS.NOT_STARTED]: deeplinkToSearch
      ? ROUTES.SEARCH_PAY_LINK
      : ROUTES.SWITCH_WELCOME,
    [PAY_LINK_USER_STATUS.SELECTING]: ROUTES.SEARCH_PAY_LINK,
    [PAY_LINK_USER_STATUS.PRESELECTED]: ROUTES.SWITCH_HOME,
    [PAY_LINK_USER_STATUS.IN_PROGRESS]: ROUTES.SWITCH_HOME,
    [PAY_LINK_USER_STATUS.FINISHED]: ROUTES.SWITCH_HOME
  }

  return statusRouteMapping[userStatus]
}

export function determinePaymentMethodForConnector({
  cards = [],
  accounts = [],
  currentPaymentMethod,
  supportedPaymentMethodTypes,
  connector
}) {
  // TODO: Remove this once credit/debit is supported
  if (isCashAppConnector(connector)) {
    return cards.findLast((card) => !isCredit(card)) || last(accounts)
  }

  if (supportedPaymentMethodTypes.includes(BILL_PAYMENT_METHOD_TYPES.CARD)) {
    return isCard(currentPaymentMethod) ? currentPaymentMethod : last(cards)
  } else {
    return isBank(currentPaymentMethod) ? currentPaymentMethod : last(accounts)
  }
}

export function paymentMethodIsSupported(
  paymentMethod,
  supportedPaymentMethodTypes
) {
  return supportedPaymentMethodTypes.includes(
    getPaymentMethodType(paymentMethod)
  )
}

export function isCashAppConnector(connector) {
  return connector._id === '667455e8f6be1a6459870cb8'
}

export function isCredit(paymentMethod) {
  return paymentMethod.cardType === 'credit'
}

export function getPaymentMethodType(paymentMethod) {
  return (
    paymentMethod &&
    (paymentMethod.expiry
      ? BILL_PAYMENT_METHOD_TYPES.CARD
      : BILL_PAYMENT_METHOD_TYPES.BANK)
  )
}

export function getPaymentMethodAccountType(paymentMethod) {
  return isBank(paymentMethod) ? paymentMethod.type : undefined
}

export function isCard(paymentMethod) {
  return getPaymentMethodType(paymentMethod) === BILL_PAYMENT_METHOD_TYPES.CARD
}

export function isBank(paymentMethod) {
  return getPaymentMethodType(paymentMethod) === BILL_PAYMENT_METHOD_TYPES.BANK
}

export function cardIsValidPaymentMethod(card) {
  return (
    /**
     * If card came from customer, we assume it can be used even if it doesn't
     * have a current token, since that token can be added via data request.
     */
    card.source === CARD_SOURCE.CUSTOMER || !card.isTokenExpired
  )
}

export function hasValidToken(paymentMethod) {
  return Boolean(paymentMethod?.hasToken && !paymentMethod.isTokenExpired)
}

export function userNeedsToAddCard({
  supportedPaymentMethodTypes = [],
  cardPaymentMethods = []
}) {
  return (
    supportedPaymentMethodTypes.includes(BILL_PAYMENT_METHOD_TYPES.CARD) &&
    !cardPaymentMethods.length
  )
}

export function removeSpaces(value) {
  return value.replace(/\s/g, '')
}

export function createBinValidator(customer) {
  const binCodes = customer.features?.payLink?.userProvidedCards?.binCodes ?? []

  const isValidationRequired = () => binCodes.length > 0

  return {
    isValidationRequired,
    isValid: (cardNumber) =>
      !isValidationRequired() ||
      binCodes.some((bin) => removeSpaces(cardNumber).startsWith(bin))
  }
}

export function createSelectionAnalyticsPayload(selection) {
  return {
    company: selection.company.name,
    status: selection.status
  }
}
