import Analytics from '@/plugins/analytics'
import Ably from '@/plugins/ably'
import { MODAL_VIEW } from '@/util/constants'

export function listenForNetworkEvents({ store }) {
  const analyticsInstance = Analytics.get()
  let offlineTimeout

  Ably.get().connection.on('disconnected', () => {
    // Clear any existing timeout
    if (offlineTimeout) {
      clearTimeout(offlineTimeout)
    }

    // Sometimes when the user returns to the app, Ably fires a disconnected event
    // immediately with a subsequent connected event. We don't want to count this
    // as an actual disconnect, so we'll wait 500ms to see if Ably fires a
    // connected event. If not, we'll consider it an actual disconnect and show the offline modal.
    offlineTimeout = setTimeout(() => {
      _wentOffline({ store, analyticsInstance })
      _waitForOnline({ store, analyticsInstance })
    }, 500)
  })

  Ably.get().connection.on('connected', () => {
    // Clear the timeout if we connect within 500ms
    if (offlineTimeout) {
      clearTimeout(offlineTimeout)
    }
  })
}

function _wentOffline({ store, analyticsInstance }) {
  if (store.state.modal.modalState.view === MODAL_VIEW.OFFLINE) {
    return
  }

  store.dispatch('network/updateIsOnline', false)

  analyticsInstance.track({
    event: 'Went offline',
    internal: true
  })

  store.dispatch('modal/openModal', { view: MODAL_VIEW.OFFLINE })
}

function _waitForOnline({ store, analyticsInstance }) {
  const onlineListener = async () => {
    await _wentOnline({ store, analyticsInstance })
    Ably.get().connection.off('connected', onlineListener)
  }

  Ably.get().connection.on('connected', onlineListener)
}

async function _wentOnline({ store, analyticsInstance }) {
  store.dispatch('network/updateIsOnline', true)

  await store.dispatch('modal/closeModal')

  analyticsInstance.track({
    event: 'Went online',
    internal: true
  })
}
