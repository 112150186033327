export const taskCompleted = {
  allDoneHere: '¡Todo listo!',
  continueBackOnOtherDevice: 'Continúe en su navegador web.',
  changeTimeline:
    'Las actualizaciones pueden tardar 1-2 ciclos de pago en surtir efecto.',
  ssChangeTimeline:
    'Las actualizaciones pueden tardar entre 30-60 días en surtir efecto.',
  linkAnotherGigAccount: 'Conectar otra cuenta de gig',
  linkAnotherAccount: 'Configurar otra cuenta',
  messageOnlyAuthCompletedTitle: '¡Buen trabajo! Ahora es nuestro turno.',
  messageOnlyAuthCompletedDeposit:
    'Estamos finalizando la actualización de su depósito directo, no es necesario que espere.',
  messageOnlyAuthCompletedVerify:
    'Estamos finalizando la vinculación de su nómina, no es necesario que espere.',
  messageOnlyAuthCompletedWithhold:
    'Estamos finalizando la actualización de sus retenciones, no es necesario que espere.',
  signInto: 'Inicie sesión en',
  tryAgain: 'Inténtelo de nuevo',
  messageLong: 'La autenticación está tardando más de lo esperado.',
  keepWaiting: 'Siga esperando',
  messageWait:
    'Si lo desea, puede salir y le actualizaremos sobre el progreso.',
  messageContinueProcess:
    'Seguiremos procesando su solicitud y le informaremos cuando esté lista.',
  messageAllSet: '¡Todo listo!',
  gigSuccessTitle: ({ customerName, connectorName }) =>
    `El depósito directo de ${connectorName} ahora está actualizado a ${customerName}.`,
  loginWithYourCredentials: 'Inicie sesión con sus credenciales',
  messageNotifiedComplete:
    'Se le notificará cuando el proceso esté completo, o si se necesita su ayuda',
  messageDepositTaskCompleted: ({ customerName }) =>
    `Su depósito directo ahora está actualizado a ${customerName}.`,
  messageReadDataTaskCompleted: '¡Éxito!',
  messageReadDataTaskCompletedDescription:
    'Sus datos de nómina se han compartido de forma segura',
  messageAuthenticationSuccessful: 'Autenticación exitosa.',
  messageCompleteDescriptionDave: `Buen trabajo iniciando sesión. Ahora es nuestro turno: actualizaremos su depósito directo en unos momentos. Tenga en cuenta que esto puede tardar hasta 5 minutos.`,
  tax: {
    completed: {
      title: '¡Todo listo!',
      description:
        'Hemos recopilado los documentos necesarios para su declaración de impuestos.',
      actionItems: {
        connectAnother: 'Conectar otra cuenta'
      }
    }
  }
}
